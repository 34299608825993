<template>
  <div class="bg-idle-white dark:bg-idle-black text-idle-black dark:text-idle-white min-h-screen">

    <ui-navbar/>

    <div class="content-main">
      <slot />
    </div>

  </div>
</template>
